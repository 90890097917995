/* eslint-disable @typescript-eslint/naming-convention */
export const constants = {
  TACTICAL_ADDON_ID: 'h3Pn50RcuWRpm1OEGvX2',
  HOSPITAL_ADDON_ID: 'Yc4Yan3jBkpAEoXfVfxh',
  CAMPUS_ADDON_ID: 'TBEGBwiqfMhrSqx3mstv',
  FIRE_EMS_ADDON_ID: 'R6ejRmaEC5huN3lVCeUA',
  HAZMAT_ADDON_ID: 'oiRxj1GqprTZUg61Tfn8',
  BASIC_SUBSCTRIPTION: 'qtZdrsw2EpLOar2TJ5p8',
  STANDARD_SUBSCTRIPTION: 'En4d6ho5r7ldIblkkEO5',
  DELUXE_SUBSCTRIPTION: 'YJ2yqdofqyAkwg6vDWRE',
  PREMIUM_SUBSCTRIPTION: 'xG0ppKAHKz5VZeQBrLud',
  SIMTAC_CITY_LESSON_PLAN: 'https://firebasestorage.googleapis.com/v0/b/simtac-dms.appspot.com/o/PDF%2FDMS-06030SimTac_LessonPlan.pdf?alt=media&token=fbb781fc-dd9a-4db3-b385-6502c582ca28',
  THEATER_LESSON_PLAN: 'https://firebasestorage.googleapis.com/v0/b/simtac-dms.appspot.com/o/PDF%2FDMS-06030SimTac_LessonPlan.pdf?alt=media&token=fbb781fc-dd9a-4db3-b385-6502c582ca28',
  SCHOOL_LESSON_PLAN: 'https://firebasestorage.googleapis.com/v0/b/simtac-dms.appspot.com/o/PDF%2FDMS-06030SimTac_LessonPlan.pdf?alt=media&token=fbb781fc-dd9a-4db3-b385-6502c582ca28',
  HOSPITAL_LESSON_PLAN: 'https://firebasestorage.googleapis.com/v0/b/simtac-dms.appspot.com/o/PDF%2FDMS-06030H_HospitalAddOn_LessonPlan.pdf?alt=media&token=7c8d46fc-2bbb-4389-af8c-24ce73c05e3b',
  CAMPUS_LESSON_PLAN: 'https://firebasestorage.googleapis.com/v0/b/simtac-dms.appspot.com/o/PDF%2FDMS-06030C_CampusAddOn_LessonPlan.pdf?alt=media&token=8f609869-3156-44b2-af54-cc481b5355fe',
  FIRE_EMS_LESSON_PLAN: 'https://firebasestorage.googleapis.com/v0/b/simtac-dms.appspot.com/o/PDF%2FDMS-06030F_Fire_EMSAddOn_LessonPlan.pdf?alt=media&token=2f94ea2a-4933-4632-a1d7-d2d4dbb7ac0a',
  TACTICAL_LESSON_PLAN: 'https://firebasestorage.googleapis.com/v0/b/simtac-dms.appspot.com/o/PDF%2FDMS-06030T_SimTac_LessonPlan_TacticalAddOn.pdf?alt=media&token=8a7c349d-9404-4d51-b1e5-c36d7e7ce07b'
};
