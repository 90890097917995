/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Timestamp } from '@angular/fire/firestore';
import { LocalFile, SimTacMap } from './local-files';

export class SimTacUser {
  userId: string;
  email: string;
  emailVerified: boolean;
  isAdmin: boolean;
  lastLogin: Timestamp;
  hasAccess: boolean;

  constructor(
    userId: string,
    email: string,
    emailVerified: boolean,
    isAdmin = false,
    lastLogin = Timestamp.now(),
    hasAccess = false
  ) {
    this.userId = userId;
    this.email = email;
    this.emailVerified = emailVerified;
    this.isAdmin = isAdmin;
    this.lastLogin = lastLogin;
    this.hasAccess = hasAccess
  }

  public getEmailDomain() {
    return this.email.replace(/.*@/, '');
  }
}

export class SimTacPurchase {
  addOnId: string;
  isActive: boolean;
  stripeId: string;
  userId: string;

  constructor(
    userId: string,
    addOnId: string,
    isActive: boolean,
    stripeId: string
  ) {
    this.userId = userId;
    this.addOnId = addOnId;
    this.isActive = isActive;
    this.stripeId = stripeId;
  }
}

export class SimTacSubscription {
  addOnId: string;
  autoRenewal: boolean;
  endDate: Timestamp;
  frequency: string;
  isActive: boolean;
  startDate: Timestamp;
  tier: string;
  userId: string;

  constructor(
    addOnId: string,
    userId: string,
    autoRenewal: boolean,
    endDate: Timestamp,
    frequency: string,
    isActive: boolean,
    startDate: Timestamp,
    tier: string
  ) {
    this.addOnId = addOnId;
    this.userId = userId;
    this.autoRenewal = autoRenewal;
    this.endDate = endDate;
    this.frequency = frequency;
    this.isActive = isActive;
    this.startDate = startDate;
    this.tier = tier;
  }
}

export class SimTacAddOn {
  addOnId: string;
  addOnType: string;
  description: string;
  frequency: Map<string, number>;
  isActive: boolean;
  name: string;
  showMore = false;
  key: string;

  constructor(
    addOnId: string,
    addOnType: string,
    description: string,
    frequency: Map<string, number>,
    isActive: boolean,
    name: string,
    key: string
  ) {
    this.addOnId = addOnId;
    this.addOnType = addOnType;
    this.description = description;
    this.frequency = frequency as Map<string, number>;
    this.isActive = isActive;
    this.name = name;
    this.key = key;
  }
}

export class SimTacTrial {
  userId: string;
  trialType: string;
  startDate: Timestamp;
  endDate: Timestamp;
  domain: string;
  premiumTrial: boolean;
  tacticalTrial: boolean;
  hospitalTrial: boolean;
  campusTrial: boolean;
  fireEmsTrial: boolean;
  hazmatTrial: boolean;

  constructor(
    userId: string,
    trialType: string,
    startDate: Timestamp,
    endDate: Timestamp,
    domain: string,
    premiumTrial = true,
    tacticalTrial = false,
    hospitalTrial = false,
    campusTrial = false,
    fireEmsTrial = false,
    hazmatTrial = false
  ) {
    this.userId = userId;
    this.trialType = trialType;
    this.startDate = startDate;
    this.endDate = endDate;
    this.domain = domain;
    this.premiumTrial = premiumTrial;
    this.tacticalTrial = tacticalTrial;
    this.hospitalTrial = hospitalTrial;
    this.campusTrial = campusTrial;
    this.fireEmsTrial = fireEmsTrial;
    this.hazmatTrial = hazmatTrial;
  }
}

export const KEYS = {
  HOSTPITAL: 'HOSTPITAL',
  TACTICAL: 'TACTICAL',
  PREMIUM: 'PREMIUM',
};

export class SimTacSession {
  admins: string[];
  allUsers: string[];
  companyDomain: string;
  creatorEmail: string;
  creatorUserId: string;
  isActive: boolean;
  map: string;
  sessionId: string;
  sessionName: string;
  viewers: string[];
  createdAt: Timestamp;
  constructor(
    admins: string[],
    allUsers: string[],
    companyDomain: string,
    creatorEmail: string,
    creatorUserId: string,
    isActive: boolean,
    map: string,
    sessionId: string,
    sessionName: string,
    viewers: string[],
    createdAt = Timestamp.now()
  ) {
    this.admins = admins;
    this.allUsers = allUsers;
    this.companyDomain = companyDomain;
    this.creatorEmail = creatorEmail;
    this.creatorUserId = creatorUserId;
    this.isActive = isActive;
    this.map = map;
    this.sessionId = sessionId;
    this.sessionName = sessionName;
    this.viewers = viewers;
    this.createdAt = createdAt;
  }
}

export class SimTacOrderEmail {
  firstAndLast: string;
  addressLine1: string;
  addressLine2: string;
  cityStateZip: string;
  email: string;
  addOnName: string;

  constructor(
    firstAndLast: string,
    addressLine1: string,
    addressLine2: string,
    cityStateZip: string,
    email: string,
    addOnName: string
  ) {
    this.firstAndLast = firstAndLast;
    this.addressLine1 = addressLine1;
    this.addressLine2 = addressLine2;
    this.cityStateZip = cityStateZip;
    this.email = email;
    this.addOnName = addOnName;
  }
}

export class SimTacResource {
  resourceId: string;
  resourceUrl: string;
  resourceName: string;
  folder: string;
  userId: string;

  constructor(
    userId: string,
    folder: string,
    resourceId: string,
    resourceUrl: string,
    resourceName: string
  ) {
    this.userId = userId;
    this.resourceId = resourceId;
    this.resourceUrl = resourceUrl;
    this.folder = folder;
    this.resourceName = resourceName;
  }
}

export class SimTacDigitalProfile {
  hasPremium: boolean;
  hasTactical: boolean;
  hasHospital: boolean;
  hasCampus: boolean;
  hasFireEMS: boolean;
  hasHazmat: boolean;
  hasTrial: boolean;
  premiumTier: SimTacSubscription;
  trial: SimTacTrial;

  constructor(
    hasPremium: boolean,
    hasTactical: boolean,
    hasHospital: boolean,
    hasCampus: boolean,
    hasFireEms: boolean,
    hasHazmat: boolean,
    hasTrial: boolean,
    premiumTier: SimTacSubscription,
    trial: SimTacTrial
  ) {
    this.hasPremium = hasPremium;
    this.hasTactical = hasTactical;
    this.hasHospital = hasHospital;
    this.hasCampus = hasCampus;
    this.hasFireEMS = hasFireEms;
    this.hasHazmat = hasHazmat;
    this.hasTrial = hasTrial;
    this.premiumTier = premiumTier;
    this.trial = trial;
  }
}

export class SimTacTab {
  name: string;
  files: Map<string, LocalFile>;
  map: SimTacMap;
  mapType: string;
  tabId: string;

  constructor(
    name: string,
    files: Map<string, LocalFile>,
    map: SimTacMap,
    mapType: string,
    tabId: string
  ) {
    this.name = name;
    this.files = files;
    this.map = map;
    this.mapType = mapType;
    this.tabId = tabId;
  }
}

export class ContactCard {
  userId: string;
  name: string;
  position: string;
  email: string;
  mobile: string;
  work: string;
  notes: string;
  image: string;
  contactCardId: string;

  constructor(
    userId: string,
    name: string,
    position: string,
    email: string,
    mobile: string,
    work: string,
    notes: string,
    image: string,
    contactCardId: string
  ) {
    this.userId = userId;
    this.name = name;
    this.position = position;
    this.email = email;
    this.mobile = mobile;
    this.work = work;
    this.notes = notes;
    this.image = image;
    this.contactCardId = contactCardId;
  }
}
