/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import {
  Component,
  AfterViewInit,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Directory, Filesystem } from '@capacitor/filesystem';
import {
  AlertController,
  LoadingController,
  MenuController,
  ModalController,
  Platform,
} from '@ionic/angular';
import { PasswordModal } from '../modals/password-modal/password-modal';
import {
  LocalFile,
  FileCoordinates,
  SimTacMap,
  SimTacMapDetails,
  SimTacCloudSaveDetails,
  SimTacFont,
} from '../models/local-files';
import { EventService } from '../services/event-service';
import { FileService } from '../services/file-service';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { Timestamp } from '@angular/fire/firestore';
import { FirestoreService } from '../services/firestore.service';
import { CreateSessionModal } from '../modals/create-session-modal/create-session-modal';
import { InvitationsModal } from '../modals/invitations-modal/invitations-modal';
import write_blob from 'capacitor-blob-writer';
import { SimTacDigitalProfile, SimTacUser } from '../models/firestore-models';

@Component({
  selector: 'app-map-selection',
  templateUrl: 'map-selection.html',
  styleUrls: ['map-selection.scss'],
})
export class MapSelectionPage implements OnInit {
  maps: Map<string, SimTacMap> = new Map();
  cloudSaves: SimTacCloudSaveDetails[] = [];
  mapNameToImage: Map<string, string> = new Map();
  passwordSuccess = false;
  userDigitalProfile: SimTacDigitalProfile;
  isCreatingLiveSession = false;
  sessionName = '';
  sessionAdmins: string[] = [];
  sessionReaders: string[] = [];
  sessionEditors: string[] = [];
  sessionAllUsers: string[] = [];
  mostRecentModalId = '';
  user: SimTacUser;
  isApp = false;
  isAtMapCapacity = false;

  constructor(
    public eventService: EventService,
    public router: Router,
    public fileSerivce: FileService,
    public changeDetectorRef: ChangeDetectorRef,
    public modalController: ModalController,
    public alertController: AlertController,
    public authService: AuthService,
    public menu: MenuController,
    public fireStoreService: FirestoreService,
    public loadingController: LoadingController,
    public platform: Platform
  ) {}

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    if (this.platform.is('ios') || this.platform.is('android')) {
      this.isApp = true;
    }
    this.eventService.savedMapList.subscribe(async (maps) => {
      this.maps = maps;
      this.maps.forEach(async (map) => {
        this.getSource(map.mapDetails.mapName, map.mapImage);
      });
    });
    this.eventService.cloudSavesList.subscribe(async (saves) => {
      this.cloudSaves = saves;
      this.cloudSaves.forEach(async (map) => {
        this.getSource(map.mapName, map.mapImage);
      });
    });
    this.authService.userDigitalProfile.subscribe((value) => {
      this.userDigitalProfile = value;
    });

    this.authService.user.subscribe((user) => {
      if (user) {
        if (!user.hasAccess) {
          this.router.navigateByUrl('no-access');
        }
        this.user = user;
        this.fileSerivce.getAllMaps();
      }
    });
    this.user = this.authService.user.value;
  }

  resetMapSelectionData() {
    this.resetMapSelectionSessionData();
    this.maps = new Map();
    this.mapNameToImage = new Map();
    this.passwordSuccess = false;
    this.userDigitalProfile = null;
    this.isCreatingLiveSession = false;
  }

  resetMapSelectionSessionData() {
    this.sessionName = '';
    this.sessionAdmins = [];
    this.sessionReaders = [];
    this.sessionEditors = [];
    this.sessionAllUsers = [];
    this.isCreatingLiveSession = false;
  }

  async openMap(
    map: string,
    isPremiumMap: boolean = false,
    cloudSave?: SimTacCloudSaveDetails
  ) {
    if (!cloudSave && this.userDigitalProfile && !this.userDigitalProfile.hasPremium && this.cloudSaves.length >= 5) {
      let message = '';
      message = 'You have reached the map limit. Please delete an existing map or become a premium member.';
      return this.alertController
        .create({
          message,
          buttons: [
            {
              text: 'OK',
              role: 'confirm',
              handler: () => {
                
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    }
    if (
      isPremiumMap &&
      (map === 'school' || map === 'theater') &&
      !this.userDigitalProfile.hasTactical
    ) {
      let message = '';
      message = 'You must own the Tactical Add-On to access this content.';
      return this.alertController
        .create({
          message,
          buttons: [
            {
              text: 'OK',
              role: 'confirm',
              handler: () => {
                this.router.navigateByUrl('addons');
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    }

    if (
      isPremiumMap &&
      map === 'hospital' &&
      !this.userDigitalProfile.hasHospital
    ) {
      let message = '';
      message = 'You must own the Hospital Add-On to access this content.';
      return this.alertController
        .create({
          message,
          buttons: [
            {
              text: 'OK',
              role: 'confirm',
              handler: () => {
                this.router.navigateByUrl('addons');
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    }

    if (
      isPremiumMap &&
      map === 'campus' &&
      !this.userDigitalProfile.hasCampus
    ) {
      let message = '';
      message = 'You must own the Campus Add-On to access this content.';
      return this.alertController
        .create({
          message,
          buttons: [
            {
              text: 'OK',
              role: 'confirm',
              handler: () => {
                this.router.navigateByUrl('addons');
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    }

    if (
      isPremiumMap &&
      map === 'fire/ems' &&
      !this.userDigitalProfile.hasFireEMS
    ) {
      let message = '';
      message = 'You must own the Fire/EMS Add-On to access this content.';
      return this.alertController
        .create({
          message,
          buttons: [
            {
              text: 'OK',
              role: 'confirm',
              handler: () => {
                this.router.navigateByUrl('addons');
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    }

    if (
      isPremiumMap &&
      map === 'hazmat' &&
      !this.userDigitalProfile.hasHazmat
    ) {
      let message = '';
      message = 'You must own the Hazmat Add-On to access this content.';
      return this.alertController
        .create({
          message,
          buttons: [
            {
              text: 'OK',
              role: 'confirm',
              handler: () => {
                this.router.navigateByUrl('addons');
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    }

    const maptemp = this.maps.get(map);
    if (maptemp && maptemp.locked && !this.passwordSuccess) {
      await this.presentPasswordModal(map);
      return;
    }
    this.passwordSuccess = false;
    if (map === 'custom') {
      await this.getCustomMap();
    }
    if (!cloudSave) {
      if (
        map !== 'school' &&
        map !== 'theater' &&
        map !== 'hospital' &&
        map !== 'campus' &&
        map !== 'fire/ems' &&
        map !== 'hazmat' &&
        map !== 'city' &&
        map !== 'custom'
      ) {
        await this.fileSerivce.getMap(map);
        if (this.isCreatingLiveSession) {
          await this.createLiveSession(
            this.sessionAdmins,
            this.sessionAllUsers,
            this.sessionName,
            map,
            this.sessionReaders
          );
        }
        this.router.navigateByUrl('/tabs');
        return;
      }
    }
    this.eventService.mapType.next(map);
    if (cloudSave) {
      if (cloudSave.mapImage.data.includes('https://')) {
        this.eventService.mapType.next(cloudSave.mapImage.data);
      }
      this.eventService.currentCloudSaveId.next(cloudSave.cloudSaveId);
      this.fireStoreService.listenForCloudSaveEvents(this.user.userId);
    }
    if (this.isCreatingLiveSession) {
      await this.createLiveSession(
        this.sessionAdmins,
        this.sessionAllUsers,
        this.sessionName,
        map,
        this.sessionReaders
      );
    }
    this.router.navigateByUrl('/tabs');
  }

  async getCustomMap() {
    await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      quality: 10,
    }).then(async (result) => {
      const png = 'data:image/png;base64,' + result.base64String;
      const name =
        this.eventService.getUniqueFileNameKey('uploaded-image') + '.png';
      const w = 0;
      const h = 0;

      const newFile: LocalFile = {
        name,
        fileId: name,
        fileName: name,
        path: name,
        fileCoordinates: new FileCoordinates(0, 0, 0, 0, 0, w, h),
        data: png,
        type: 'image',
        flipped: false,
        flippedFileName: '',
        isPremiumItem: false,
        addOnParentId: '',
        font: new SimTacFont(),
        subText: '',
        subTextFont: new SimTacFont(),
        subTextMirror: false,
        contacts: []
      };

      const fileBlob = new Blob([JSON.stringify(newFile)], {
        type: 'application/json',
      });
      write_blob({
        // The 'path' option should be a string describing where to write the file. It
        // may be specified as an absolute URL (beginning with "file://") or a relative
        // path, in which case it is assumed to be relative to the 'directory' option.
        path: name,
        // The 'directory' option is used to resolve 'path' to a location on the disk.
        // It is ignored if the 'path' option begins with "file://".

        directory: Directory.Documents,

        // The 'blob' option must be a Blob, which will be written to the file. The file
        // on disk is overwritten, not appended to.

        blob: fileBlob,

        // Fast mode vastly improves read and write speeds on the web platform. For
        // files written with 'fast_mode' set to true, Filesystem.readFile will produce
        // a Blob rather than a Base64-encoded string. The 'fast_mode' option is
        // ignored on iOS and Android. For backwards compatibility, it defaults to
        // false.

        fast_mode: true,

        // If the 'recursive' option is 'true', intermediate directories will be created
        // as required. It defaults to 'false' if not specified.

        recursive: true,

        // If 'write_blob' falls back to its alternative strategy on failure, the
        // 'on_fallback' function will be called with the underlying error. This can be
        // useful to diagnose slow writes. It is optional.

        // See the "Fallback mode" section below for a detailed explanation.

        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        on_fallback: (error) => {
          console.error(error);
        },
      }).then(() => {
        console.log('Photo written.');
      });

      // const b2a = btoa(JSON.stringify(newFile));
      // await Filesystem.writeFile({
      //   path: name,
      //   data: b2a,
      //   directory: Directory.Documents,
      // });
      this.eventService.customMapImage.next(newFile);
      return newFile;
    });
  }

  async mapContainsKey(key) {
    return this.mapNameToImage.has(key);
  }

  async getSource(mapName, value) {
    if (value.path.includes('assets')) {
      this.mapNameToImage.set(
        mapName,
        '../..' + value.path + '/' + value.fileName
      );
    } else {
      const img = value.data;
      this.mapNameToImage.set(mapName, img);
      this.changeDetectorRef.detectChanges();
    }
  }

  async presentPasswordModal(mapKey: string) {
    const mapPass = this.maps.get(mapKey).mapPassword;
    const modal = await this.modalController.create({
      component: PasswordModal,
      cssClass: 'password-modal',
      componentProps: {
        passwordSuccess: () => {
          this.passwordSuccess = true;
          this.openMap(mapKey);
        },
        currentPassword: mapPass,
      },
    });
    await modal.present();
    return modal;
  }

  purchaseClicked() {
    this.menu.close();
    this.router.navigateByUrl('addons');
  }

  paymentClicked() {}

  adminClicked() {
    this.router.navigateByUrl('admin');
  }

  liveSessionClicked() {
    if (!this.userDigitalProfile.hasPremium) {
      this.alertController
        .create({
          message: 'Live sessions are for premium members.',
          buttons: [
            {
              text: 'OK',
              role: 'confirm',
              handler: () => {
                this.router.navigateByUrl('addons');
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    } else {
      this.isCreatingLiveSession = true;
      this.presentLiveSessionModal();
    }
  }

  invitesClicked() {
    if (this.eventService.invitations.value.length === 0) {
      return this.alertController
        .create({
          message: 'You currently have no invites.',
          buttons: [
            {
              text: 'OK',
              role: 'confirm',
              handler: () => {},
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    }

    this.presentInvitationsModal();
  }

  aboutClicked() {
    this.alertController
      .create({
        message: 'Current Version: ' + environment.version,
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
            handler: () => {
              this.router.navigateByUrl('map-selection');
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

  logoutClicked() {
    this.alertController
      .create({
        message: 'Logout?',
        buttons: [
          {
            text: 'cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'logout',
            role: 'confirm',
            handler: () => {
              this.authService.logout().then(() => {
                this.menu.close();
                this.router.navigateByUrl('/');
                window.location.reload();
              });
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

  async presentLiveSessionModal() {
    const modalId = crypto.randomUUID();
    const modal = await this.modalController.create({
      component: CreateSessionModal,
      cssClass: 'create-session-modal',
      componentProps: {
        randomUUID: modalId,
        sessionDetailsCaptured: (
          sessionName: string,
          sessionAdmins: string[],
          sessionReaders: string[],
          sessionEditors: string[],
          sessionAllUsers: string[],
          modalSessionId: string
        ) => {
          this.menu.close();
          this.sessionName = sessionName;
          this.sessionAdmins = sessionAdmins;
          this.sessionReaders = sessionReaders;
          this.sessionEditors = sessionEditors;
          this.sessionAllUsers = sessionAllUsers;
          this.mostRecentModalId = modalSessionId;
          this.alertController
            .create({
              message: 'Select a map to start the live session.',
              buttons: [
                {
                  text: 'OK',
                  role: 'confirm',
                  handler: () => {
                    this.menu.close();
                    console.log('Awaiting map selection for live session...');
                  },
                },
              ],
            })
            .then((alert) => {
              alert.present();
            });
        },
      },
    });
    modal.onDidDismiss().then(() => {
      if (this.mostRecentModalId !== modalId) {
        this.resetMapSelectionSessionData();
      }
    });
    await modal.present();
    return modal;
  }

  async presentInvitationsModal() {
    const modal = await this.modalController.create({
      component: InvitationsModal,
      cssClass: 'invitations-modal',
      componentProps: {
        invitations: this.eventService.invitations.value,
        connectToSession: (sessionId: string) => {
          this.menu.close();
          const session = this.eventService.invitations.value.find(
            (sessionToFind) => sessionToFind.sessionId === sessionId
          );
          this.eventService.currentSession.next(sessionId);
          this.eventService.currentSessionObj.next(session);
          this.fireStoreService.listenForEvents(this.user.userId);
          this.openMap(session.map);
        },
      },
    });

    await modal.present();
    return modal;
  }

  async createLiveSession(
    admins: string[],
    allUsers: string[],
    sessionName: string,
    map: string,
    viewers: string[]
  ): Promise<boolean> {
    if (allUsers.length <= 1) {
      this.resetMapSelectionSessionData();
      this.alertController
        .create({
          message: 'An error occurred: No users added to session.',
          buttons: [
            {
              text: 'OK',
              role: 'confirm',
              handler: () => {
                console.log('Live session cancelled.');
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
      return false;
    }

    if (sessionName === '') {
      this.resetMapSelectionSessionData();
      this.alertController
        .create({
          message: 'An error occurred: Session name missing.',
          buttons: [
            {
              text: 'OK',
              role: 'confirm',
              handler: () => {
                console.log('Live session cancelled.');
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
      return false;
    }

    const loader = await this.loadingController.create({
      message: 'Creating a live session...',
      duration: 3000,
    });
    await loader.present();

    const mapDetails = new SimTacMapDetails(sessionName, '', '', '');
    this.eventService.currentMapDetails.next(mapDetails);

    const user = this.authService.user.value;
    const companyDomain = user.getEmailDomain();
    const creatorEmail = user.email;
    const creatorUserId = user.userId;
    const sessionDTO = await this.fireStoreService.createNewLiveSession(
      admins,
      allUsers,
      companyDomain,
      creatorEmail,
      creatorUserId,
      map,
      sessionName,
      viewers,
      this.user.userId
    );

    loader.dismiss();
    if (sessionDTO) {
      return true;
    }

    return false;
  }

  manageContactsClicked() {
    this.router.navigateByUrl('manage-contacts');
  }
}
