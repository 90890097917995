/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  Auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  User,
  sendEmailVerification,
  UserCredential,
  sendPasswordResetEmail,
} from '@angular/fire/auth';
import { Timestamp } from '@angular/fire/firestore';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { constants } from '../constants/constants';
import {
  SimTacDigitalProfile,
  SimTacPurchase,
  SimTacSubscription,
  SimTacTrial,
  SimTacUser,
} from '../models/firestore-models';
import { EventService } from './event-service';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user: BehaviorSubject<SimTacUser> = new BehaviorSubject<SimTacUser>(
    null
  );
  public userId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public userIsAuthenticated: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public userPurchases: BehaviorSubject<SimTacPurchase[]> = new BehaviorSubject<
    SimTacPurchase[]
  >([]);
  public userSubscriptions: BehaviorSubject<SimTacSubscription[]> =
    new BehaviorSubject<SimTacSubscription[]>([]);
  public userTrials: BehaviorSubject<SimTacTrial> =
    new BehaviorSubject<SimTacTrial>(null);

  public userDigitalProfile: BehaviorSubject<SimTacDigitalProfile> =
    new BehaviorSubject<SimTacDigitalProfile>(
      new SimTacDigitalProfile(false, false, false, false, false, false, false, null, null)
    );

  constructor(
    private auth: Auth,
    private firestoreService: FirestoreService,
    private loadingController: LoadingController,
    private eventService: EventService
  ) {
    this.auth.onAuthStateChanged(async (user: User) => {
      if (user) {
        const loader = await this.loadingController.create({
          message: 'Loading user data...',
        });
        loader.present();
        this.firestoreService.updateUserLastLogin(user.uid);
        let counter = 0;
        this.userId.next(user.uid);
        this.userIsAuthenticated.next(true);
        this.firestoreService
          .getUserById(user.uid)
          .then((simTacUser: SimTacUser) => {
            this.user.next(simTacUser);
            counter++;
            this.firestoreService
              .getTrialsByDomain(simTacUser.getEmailDomain())
              .then((simTacTrials: SimTacTrial[]) => {
                if (simTacTrials && simTacTrials.length > 0) {
                  this.userTrials.next(simTacTrials[0]);
                  this.updateDigitalProfile();
                }
              });
            if (counter === 3) {
              loader.dismiss();
            }
          });
        this.firestoreService
          .getPurchasesByUserId(user.uid)
          .then((simTacPurchases: SimTacPurchase[]) => {
            this.userPurchases.next(simTacPurchases);
            this.updateDigitalProfile();
            counter++;
            if (counter === 3) {
              loader.dismiss();
            }
          });
        this.firestoreService
          .getSubscriptionsByUserId(user.uid)
          .then((simTacSubscriptions: SimTacSubscription[]) => {
            this.userSubscriptions.next(simTacSubscriptions);
            this.updateDigitalProfile();
            counter++;
            if (counter === 3) {
              loader.dismiss();
            }
          });
        this.firestoreService
          .getTrialByUserId(user.uid)
          .then((simTacTrials: SimTacTrial[]) => {
            if (simTacTrials && simTacTrials.length > 0) {
              simTacTrials.forEach((trial) => {
                if (
                  trial.startDate < Timestamp.now() &&
                  trial.endDate > Timestamp.now()
                ) {
                  this.userTrials.next(trial);
                }
              });
              this.updateDigitalProfile();
            }
          });
        this.firestoreService.getLiveSessions(user.email);
      } else {
        this.userIsAuthenticated.next(false);
      }
    });
  }

  userIsAdmin() {
    return this.user.value.isAdmin;
  }

  async register({ email, password }) {
    try {
      const user = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      this.sendVerificationEmail(user);
      return user;
    } catch (e) {
      return null;
    }
  }

  async login({ email, password }) {
    try {
      const user = await signInWithEmailAndPassword(this.auth, email, password);
      return user;
    } catch (e) {
      return null;
    }
  }

  async sendVerificationEmail(user) {
    try {
      await sendEmailVerification(user);
    } catch (e) {
      return null;
    }
  }

  async sendPasswordResetEmail(email: string) {
    try {
      await sendPasswordResetEmail(this.auth, email);
    } catch (e) {
      return null;
    }
  }

  async updateUserEmail(userId: string, newEmail: string) {
    const body = {
      userId,
      email: newEmail,
    };
    const stringified = JSON.stringify(body);
    const response = await fetch(
      'https://us-central1-simtac-dms.cloudfunctions.net/updateUserEmail',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: stringified,
      }
    );
    return response;
  }

  async updateUserPassword(userId: string, newPassword: string) {
    const body = {
      userId,
      password: newPassword,
    };
    const response = await fetch(
      'https://us-central1-simtac-dms.cloudfunctions.net/updateUserPassword',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    );
    return response;
  }

  async createNewSimTacUser(email: string, password: string) {
    const body = {
      email,
      password,
    };
    const response = await fetch(
      'https://us-central1-simtac-dms.cloudfunctions.net/createNewUser',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    );
    return response;
  }

  async deleteNewSimTacUser(userId: string) {
    const body = {
      userId,
    };
    const response = await fetch(
      'https://us-central1-simtac-dms.cloudfunctions.net/deleteUserFromSimTac',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    );
    return response;
  }

  async logout() {
    await signOut(this.auth);
    await this.resetData();
  }

  async resetData() {
    this.user.next(null);
    this.userId.next('');
    this.userIsAuthenticated.next(false);
    this.userTrials.next(null);
    this.userPurchases.next(null);
    this.userDigitalProfile.next(null);
    this.eventService.resetAllData.next(true);
    window.localStorage.clear();
    await this.deleteAllCookies();
  }

  async deleteAllCookies() {
    const cookies = document.cookie.split(';');

    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  private updateDigitalProfile() {
    const currentPremium = this.userSubscriptions.value.find(
      (subscription) =>
        subscription.isActive && subscription.endDate > Timestamp.now()
    );

    const currentTrial = this.userTrials.value;

    const hasTrial =
      currentTrial &&
      currentTrial.startDate < Timestamp.now() &&
      currentTrial.endDate > Timestamp.now();

    const currentDigitalProfile = this.userDigitalProfile.value;

    const currentPurchases = this.userPurchases.value;
    if (currentPurchases) {
      currentPurchases.forEach((purchase) => {
        if (purchase.isActive) {
          if (purchase.addOnId === constants.TACTICAL_ADDON_ID) {
            currentDigitalProfile.hasTactical = true;
          }
          if (purchase.addOnId === constants.HOSPITAL_ADDON_ID) {
            currentDigitalProfile.hasHospital = true;
          }
          if (purchase.addOnId === constants.CAMPUS_ADDON_ID) {
            currentDigitalProfile.hasCampus = true;
          }
          if (purchase.addOnId === constants.FIRE_EMS_ADDON_ID) {
            currentDigitalProfile.hasFireEMS = true;
          }
          if (purchase.addOnId === constants.HAZMAT_ADDON_ID) {
            currentDigitalProfile.hasHazmat = true;
          }
        }
      });
    }

    if (currentPremium) {
      currentDigitalProfile.hasPremium = true;
      currentDigitalProfile.premiumTier = currentPremium;
    }

    if (hasTrial) {
      currentDigitalProfile.hasTrial = true;
      currentDigitalProfile.hasTactical =
        currentDigitalProfile.hasTactical || currentTrial.tacticalTrial;
      currentDigitalProfile.hasHospital =
        currentDigitalProfile.hasHospital || currentTrial.hospitalTrial;
      currentDigitalProfile.hasCampus =
        currentDigitalProfile.hasCampus || currentTrial.campusTrial;
      currentDigitalProfile.hasFireEMS =
        currentDigitalProfile.hasFireEMS || currentTrial.fireEmsTrial;
      currentDigitalProfile.hasHazmat =
        currentDigitalProfile.hasHazmat || currentTrial.hazmatTrial;
      currentDigitalProfile.trial = currentTrial;
    }

    this.userDigitalProfile.next(currentDigitalProfile);
  }
}
