/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Input } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { ContactCard } from 'src/app/models/firestore-models';
import { Camera, CameraResultType } from '@capacitor/camera';
import { EventService } from 'src/app/services/event-service';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'details-modal',
  templateUrl: 'details-modal.html',
  styleUrls: ['details-modal.scss'],
})
export class DetailsModal {
  @Input() contactCard: ContactCard;
  @Input() userId: string;
  @Input() saveContactDetails: any;
  @Input() createContact: any;
  @Input() selectContact: any;
  @Input() onDismiss: any;

  public nameValue: string;
  public positionValue: string;
  public emailValue: string;
  public mobileValue: string;
  public workValue: string;
  public notesValue: string;
  public imgValue: string;
  public searching = false;
  public contactList: ContactCard[] = [];
  public searchResults: ContactCard[] = [];


  constructor(
    private modalController: ModalController,
    private eventService: EventService,
    private firestoreService: FirestoreService,
    private loadingController: LoadingController
  ) {
  }

  ngOnInit() {
    if (this.contactCard) {
      this.nameValue = this.contactCard.name;
      this.positionValue = this.contactCard.position;
      this.emailValue = this.contactCard.email;
      this.mobileValue = this.contactCard.mobile;
      this.workValue = this.contactCard.work;
      this.notesValue = this.contactCard.notes;
      this.imgValue = this.contactCard.image;
    }
  }

  createNewContact() {
    this.nameValue = "";
    this.positionValue = "";
    this.emailValue = "";
    this.mobileValue = "";
    this.workValue = "";
    this.notesValue = "";
    this.imgValue = "";
    this.contactCard = undefined;
  }

  changeContact(contact: ContactCard) {
    this.nameValue = contact.name;
    this.positionValue = contact.position;
    this.emailValue = contact.email;
    this.mobileValue = contact.mobile;
    this.workValue = contact.work;
    this.notesValue = contact.notes;
    this.imgValue = contact.image;
    this.contactCard = contact;
    this.closeSearch();
  }

  async confirmDetails() {
    if (this.contactCard) {
      const contact = new ContactCard(this.contactCard.userId, this.nameValue, this.positionValue, this.emailValue, this.mobileValue, this.workValue, this.notesValue, this.imgValue, this.contactCard.contactCardId);
      await this.saveContactDetails(contact);
    } else {
      const contact = new ContactCard(this.userId, this.nameValue, this.positionValue, this.emailValue, this.mobileValue, this.workValue, this.notesValue, this.imgValue, '');
      await this.createContact(contact);
    }
    await this.selectContact(this.contactCard);
    this.dismiss();
  }

  async searchContact() {
    this.searching = true;
    this.contactList = this.eventService.allContactCards.value;
    this.searchResults = [];
  }

  async closeSearch() {
    this.searching = false;
    this.searchResults = [];
  }

  async updateSearchResults($event) {
    let searchValue = $event.detail.value.toLocaleLowerCase();
    this.searchResults = [];

    if (!searchValue) {
      return;
    }

    this.contactList.forEach((contact: ContactCard) => {
      if (contact.name.toLocaleLowerCase().includes(searchValue) || contact.email.toLocaleLowerCase().includes(searchValue) || contact.position.toLocaleLowerCase().includes(searchValue) || contact.mobile?.toString().toLocaleLowerCase().includes(searchValue) || contact.work?.toString().toLocaleLowerCase().includes(searchValue) || contact.notes.toLocaleLowerCase().includes(searchValue)) {
        this.searchResults.push(contact);
      }
    })
  }

  async chooseContactToAdd() {
    await this.selectContact(this.contactCard);
    this.dismiss();
  }

  dismiss() {
    this.onDismiss();
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  async uploadImageToFirebase(data: any) {
    let imgData = data[0];
    const name =
    this.getUniqueFileNameKey('abc') + '.png';
    const uploadTask = this.firestoreService.uploadImageToFirebase(imgData, name);
    let loader = await this.loadingController.create({
      message: 'Uploading...',
      duration: 3000,
    });
    await loader.present();
    let imgUrl = '';
    await uploadTask.then(async (uploadResult) => {
      imgUrl = await uploadResult.ref.getDownloadURL();
      console.log(imgUrl);
      loader.dismiss();
    });
    }

  public getUniqueFileNameKey(fileName: string) {
    const rnd = Math.random();
    const uniqueKey = fileName + rnd.toString();
    return uniqueKey;
  }

  selectPhotoFromGallery() {
    Camera.getPhoto({ resultType: CameraResultType.Base64, quality: 10 }).then(
      async (result) => {
        const png = 'data:image/png;base64,' + result.base64String;
        const name =
          this.eventService.getUniqueFileNameKey(this.userId) + '.png';
        const uploadTask = this.firestoreService.uploadImageToFirebase(
          result.base64String,
          name
        );
        let loader = await this.loadingController.create({
          message: 'Uploading...',
          duration: 3000,
        });
        await loader.present();
        let imgUrl = '';
        await uploadTask.then(async (uploadResult) => {
          imgUrl = await uploadResult.ref.getDownloadURL();
          loader.dismiss();
          if (this.contactCard)
          this.contactCard.image = imgUrl;
          this.imgValue = imgUrl;
        });
      }
    );
  }
}
