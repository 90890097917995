/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ContactCard, SimTacResource, SimTacSession, SimTacTab } from '../models/firestore-models';
import {
  Directory,
  FileCoordinates,
  LocalFile,
  SimTacCloudSaveDetails,
  SimTacFont,
  SimTacMap,
  SimTacMapDetails,
  UndoRedoAction,
} from '../models/local-files';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  public filesOnScreenSubject: Subject<Map<string, LocalFile>> = new Subject();
  public filesOnScreen: Map<string, LocalFile> = new Map();
  public currentFolderSubject: Subject<Directory> = new Subject();
  public currentMenuSelection: Subject<string> = new Subject<string>();
  public currentItemFocused: Subject<boolean> = new Subject<boolean>();
  public selectedColor: Subject<string> = new Subject<string>();
  public lineWidth: Subject<number> = new Subject<number>();
  public fontSize: Subject<number> = new Subject<number>();
  public fontWeight: Subject<number> = new Subject<number>();
  public fontIsItalic: Subject<boolean> = new Subject<boolean>();
  public subtextFont: BehaviorSubject<SimTacFont> = new BehaviorSubject<SimTacFont>(new SimTacFont());
  public subtextMirrored: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public imageButtonHit: Subject<string> = new Subject<string>();
  public mapType: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public customMapImage: BehaviorSubject<LocalFile> =
    new BehaviorSubject<LocalFile>(null);
  public currentMapSaved: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public savedMapList: BehaviorSubject<Map<string, SimTacMap>> =
    new BehaviorSubject<Map<string, SimTacMap>>(new Map());
  public cloudSavesList: BehaviorSubject<SimTacCloudSaveDetails[]> =
    new BehaviorSubject<SimTacCloudSaveDetails[]>([]);
  public exportImageEvent: Subject<string> = new Subject<string>();
  public currentlySelectedItem: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  public expandMap: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public currentMap: BehaviorSubject<SimTacMap> =
    new BehaviorSubject<SimTacMap>(null);
  public currentMapDetails: BehaviorSubject<SimTacMapDetails> =
    new BehaviorSubject<SimTacMapDetails>(new SimTacMapDetails('', '', '', ''));
  public uneditedMapDetails: BehaviorSubject<SimTacMapDetails> =
    new BehaviorSubject<SimTacMapDetails>(new SimTacMapDetails('', '', '', ''));
  public mapLocked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public mapLockedButtonActive: Subject<boolean> = new Subject<boolean>();

  public mapLockPassword: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  public userResources: BehaviorSubject<SimTacResource[]> =
  new BehaviorSubject<SimTacResource[]>([]);

  public purchasingAddonId: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  public purchasingAddonType: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  public currentSession: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  public currentSessionObj: BehaviorSubject<SimTacSession> =
    new BehaviorSubject<SimTacSession>(null);

  public currentCloudSaveId: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  public hostMapImageRenderedSize: BehaviorSubject<{
    width: number;
    height: number;
  }> = new BehaviorSubject<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  public invitations: BehaviorSubject<SimTacSession[]> = new BehaviorSubject<
    SimTacSession[]
  >([]);

  public resetAllData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public changeItemColorToSelectedColor: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public updateDrawWidth: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public copiedObject: BehaviorSubject<LocalFile> =
    new BehaviorSubject<LocalFile>(null);

  public allContactCards: BehaviorSubject<ContactCard[]> = new BehaviorSubject<ContactCard[]>([]);


  // UNDO/REDO STACK LOGIC
  /*
    For the undo stack, the idea is to have the ACTION be the key and the associated file be the object that was altered.
    Possible Actions:
      - MOVE
      - ADD
      - DELETE
      - ALTER <-- This is functionally the same as move EG replace the object in the fileList w/ the object here and save.
      - FLIPPED

      An Item that is popped from here will overwrite the item in the fileList. The existing item in the file list should be sent to the redo stack if in the undo stack and vice versa.
    */
  public undoStackMap: Array<UndoRedoAction> = [];
  public redoStackMap: Array<UndoRedoAction> = [];

  public performMapSave: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public activeTab: BehaviorSubject<SimTacTab> =
    new BehaviorSubject<SimTacTab>(null);

  public allTabs: BehaviorSubject<Map<string, SimTacTab>> =
    new BehaviorSubject<Map<string, SimTacTab>>(new Map());

  public performTabChange: BehaviorSubject<Boolean> =
    new BehaviorSubject<Boolean>(false);

  constructor() {}

  public resetData() {
    this.filesOnScreenSubject.next(new Map<string, LocalFile>());
    this.filesOnScreen = new Map<string, LocalFile>();
    this.currentFolderSubject = new Subject<Directory>();
    this.currentMenuSelection = new Subject<string>();
    this.selectedColor = new Subject<string>();
    this.lineWidth = new Subject<number>();
    this.fontSize = new Subject<number>();
    this.imageButtonHit = new Subject<string>();
    this.mapType = new BehaviorSubject<string>('');
    this.customMapImage = new BehaviorSubject<LocalFile>(null);
    this.currentMapSaved = new BehaviorSubject<boolean>(false);
    this.exportImageEvent = new Subject<string>();
    this.currentlySelectedItem = new BehaviorSubject<string>('');
    this.currentMapDetails = new BehaviorSubject<SimTacMapDetails>(
      new SimTacMapDetails('', '', '', '')
    );
    this.uneditedMapDetails = new BehaviorSubject<SimTacMapDetails>(
      new SimTacMapDetails('', '', '', '')
    );
    this.mapLocked.next(false);
    this.mapLockPassword.next('');
    this.hostMapImageRenderedSize.next({
      width: 0,
      height: 0,
    });
    this.undoStackMap = [];
    this.redoStackMap = [];
    this.currentSession = new BehaviorSubject<string>('');
    this.currentSessionObj = new BehaviorSubject<SimTacSession>(null);
    this.copiedObject = new BehaviorSubject<LocalFile>(null);
    this.updateDrawWidth = new BehaviorSubject<boolean>(false);
    this.changeItemColorToSelectedColor = new BehaviorSubject<boolean>(false);
    this.invitations = new BehaviorSubject<SimTacSession[]>([]);
    this.mapLockedButtonActive = new BehaviorSubject<boolean>(false);
  }

  public resetMapData() {
    this.resetAllData.next(true);
    this.resetAllData.next(false);
  }

  public addFileToScreen(localFile: LocalFile) {
    const uniqueFileNameKey = this.getUniqueFileNameKey(localFile.fileName);
    const coordinates = new FileCoordinates(
      localFile.fileCoordinates.x,
      localFile.fileCoordinates.y,
      localFile.fileCoordinates.transformx,
      localFile.fileCoordinates.transformy,
      localFile.fileCoordinates.transformz,
      localFile.fileCoordinates.width,
      localFile.fileCoordinates.height,
      this.filesOnScreen.size + 1001,
      localFile.fileCoordinates.rotate
    );
    const file = new LocalFile(
      localFile.name,
      localFile.fileName,
      localFile.path,
      coordinates,
      localFile.data,
      localFile.type,
      localFile.flippedFileName,
      localFile.flipped,
      localFile.isPremiumItem,
      localFile.addOnParentId,
      localFile.font,
      localFile.subText,
      localFile.subTextFont
    );
    file.fileId = uniqueFileNameKey;
    this.filesOnScreen.set(uniqueFileNameKey, file);
    this.filesOnScreenSubject.next(this.filesOnScreen);
    this.currentMapSaved.next(false);
    const undoAction = new UndoRedoAction('ADD', file);
    this.undoStackMap.push(undoAction);
    return uniqueFileNameKey
  }

  public removeFileFromScreen(fileName: string): boolean {
    if (this.filesOnScreen.has(fileName)) {
      const file = this.filesOnScreen.get(fileName);
      this.filesOnScreen.delete(fileName);
      this.filesOnScreenSubject.next(this.filesOnScreen);
      this.currentMapSaved.next(false);
      const undoAction = new UndoRedoAction('REMOVE', file);
      this.undoStackMap.push(undoAction);
      return true;
    }
    return false;
  }

  public renameFileFromScreen(fileName: string, name: string): boolean {
    if (this.filesOnScreen.has(fileName)) {
      const file = this.filesOnScreen.get(fileName);
      file.name = name;
      this.filesOnScreen.set(fileName, file);
      this.filesOnScreenSubject.next(this.filesOnScreen);
      this.currentMapSaved.next(false);
      return true;
    }
    return false;
  }

  public getUniqueFileNameKey(fileName: string) {
    const rnd = Math.random();
    const uniqueKey = fileName + rnd.toString();
    if (this.filesOnScreen.has(uniqueKey)) {
      return this.getUniqueFileNameKey(fileName);
    }
    return uniqueKey;
  }

  public setCurrentFolder(directory: Directory) {
    this.currentFolderSubject.next(directory);
  }

  public flipFile(fileName: string) {
    let item;
    if (fileName) {
      item = this.filesOnScreen.get(fileName);
    } else {
      item = this.filesOnScreen.get(this.currentlySelectedItem.value);
    }

    if (item && item.flippedFileName && item.flippedFileName !== '') {
      item.flipped = !item.flipped;
      this.filesOnScreen.set(this.currentlySelectedItem.value, item);
      this.currentlySelectedItem.next(this.currentlySelectedItem.value);
      this.filesOnScreenSubject.next(this.filesOnScreen);
    }
  }

  public undoAction() {
    const action = this.undoStackMap.pop();
    let redoAction;
    if (action) {
      switch (action.action) {
        case 'ADD':
          const fileToDelete = this.filesOnScreen.get(action.file.fileId);
          redoAction = new UndoRedoAction('DELETE', fileToDelete);
          this.redoStackMap.push(redoAction);
          this.filesOnScreen.delete(action.file.fileId);
          this.filesOnScreenSubject.next(this.filesOnScreen);
          break;
        case 'DELETE':
          const fileToAdd = action.file;
          redoAction = new UndoRedoAction('ADD', fileToAdd);
          this.redoStackMap.push(redoAction);
          this.filesOnScreen.set(fileToAdd.fileId, fileToAdd);
          this.filesOnScreenSubject.next(this.filesOnScreen);
          break;
        case 'MOVE':
          const currentMovedFile = this.filesOnScreen.get(action.file.fileId);
          const fileToUndoMove = action.file;
          redoAction = new UndoRedoAction('MOVE', currentMovedFile);
          this.redoStackMap.push(redoAction);
          this.filesOnScreen.set(fileToUndoMove.fileId, fileToUndoMove);
          this.filesOnScreenSubject.next(this.filesOnScreen);
          break;
        case 'ALTER':
          const currentAlteredFile = this.filesOnScreen.get(action.file.fileId);
          const fileToUndoAlter = action.file;
          redoAction = new UndoRedoAction('ALTER', currentAlteredFile);
          this.redoStackMap.push(redoAction);
          this.filesOnScreen.set(fileToUndoAlter.fileId, fileToUndoAlter);
          this.filesOnScreenSubject.next(this.filesOnScreen);
          break;
        case 'FLIPPED':
          redoAction = new UndoRedoAction('FLIPPED', action.file);
          this.redoStackMap.push(redoAction);
          this.flipFile(action.file.fileId);
          break;
      }
      this.performMapSave.next(true);
    }
  }

  public redoAction() {
    const action = this.redoStackMap.pop();
    let redoAction;
    if (action) {
      switch (action.action) {
        case 'ADD':
          const fileToDelete = this.filesOnScreen.get(action.file.fileId);
          redoAction = new UndoRedoAction('DELETE', fileToDelete);
          this.undoStackMap.push(redoAction);
          this.filesOnScreen.delete(action.file.fileId);
          this.filesOnScreenSubject.next(this.filesOnScreen);
          break;
        case 'DELETE':
          const fileToAdd = action.file;
          redoAction = new UndoRedoAction('ADD', fileToAdd);
          this.undoStackMap.push(redoAction);
          this.filesOnScreen.set(fileToAdd.fileId, fileToAdd);
          this.filesOnScreenSubject.next(this.filesOnScreen);
          break;
        case 'MOVE':
          const currentMovedFile = this.filesOnScreen.get(action.file.fileId);
          const fileToUndoMove = action.file;
          redoAction = new UndoRedoAction('MOVE', currentMovedFile);
          this.undoStackMap.push(redoAction);
          this.filesOnScreen.set(fileToUndoMove.fileId, fileToUndoMove);
          this.filesOnScreenSubject.next(this.filesOnScreen);
          break;
        case 'ALTER':
          const currentAlteredFile = this.filesOnScreen.get(action.file.fileId);
          const fileToUndoAlter = action.file;
          redoAction = new UndoRedoAction('ALTER', currentAlteredFile);
          this.undoStackMap.push(redoAction);
          this.filesOnScreen.set(fileToUndoAlter.fileId, fileToUndoAlter);
          this.filesOnScreenSubject.next(this.filesOnScreen);
          break;
        case 'FLIPPED':
          redoAction = new UndoRedoAction('FLIPPED', action.file);
          this.undoStackMap.push(redoAction);
          this.flipFile(action.file.fileId);
          break;
      }
      this.performMapSave.next(true);
    }
  }

  public pushToUndoStack(action: UndoRedoAction) {
    if (action) {
      const coordinates = new FileCoordinates(
        action.file.fileCoordinates.x,
        action.file.fileCoordinates.y,
        action.file.fileCoordinates.transformx,
        action.file.fileCoordinates.transformy,
        action.file.fileCoordinates.transformz,
        action.file.fileCoordinates.width,
        action.file.fileCoordinates.height,
        action.file.fileCoordinates.zindex,
        action.file.fileCoordinates.rotate
      );
      const font = new SimTacFont(
        action.file.font?.font,
        action.file.font?.color,
        action.file.font?.fontSize,
        action.file.font?.fontWeight,
        action.file.font?.isItalic
        );
      const newFile = new LocalFile(
        action.file.name,
        action.file.fileName,
        action.file.path,
        coordinates,
        action.file.data,
        action.file.type,
        action.file.flippedFileName,
        action.file.flipped,
        action.file.isPremiumItem,
        action.file.addOnParentId,
        font
      );
      newFile.fileId = action.file.fileId;
      const alteredAction = new UndoRedoAction(action.action, newFile);
      this.undoStackMap.push(alteredAction);
      this.redoStackMap = [];
    }
  }
}
