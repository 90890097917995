import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { File } from '@ionic-native/file/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { CommonModule } from '@angular/common';
import { MapSelectionPage } from './map-selection/map-selection';
import { HomePage } from './home/home';
import { DeleteModal } from './modals/delete-modal/delete-modal';
import { DirectivesModule } from './directives/directives.module';
import { RenameModal } from './modals/rename-modal/rename-modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapMetaDataModal } from './modals/map-meta-data-modal/map-meta-data-modal';
import { PasswordModal } from './modals/password-modal/password-modal';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import {
  provideAuth,
  getAuth,
  initializeAuth,
  indexedDBLocalPersistence,
} from '@angular/fire/auth';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { Stripe } from '@ionic-native/stripe/ngx';
import { StripePage } from './stripe/stripe.page';
import { HttpClientModule } from '@angular/common/http';
import { SuccessPage } from './stripe/success/success.page';
import { PaymentErrorPage } from './stripe/error/payment-error.page';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AddOnsPage } from './add-ons/add-ons';
import { StripeModal } from './modals/stripe-modal/stripe-modal';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/compat/storage';
import { CreateSessionModal } from './modals/create-session-modal/create-session-modal';
import { InvitationsModal } from './modals/invitations-modal/invitations-modal';
import { ShippingDetailsModal } from './modals/shipping-details-modal/shipping-details-modal';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { SuccessRedirectPage } from './stripe/success-redirect/success-redirect.page';
import { AdminPage } from './admin/admin';
import { ForgotPasswordModal } from './modals/forgot-password-modal/forgot-password-modal';
import { NoAccessPage } from './no-access/no-access';
import { MapSelectModal } from './modals/map-select-modal/map-select-modal';
import { ManageContactsPage } from './manage-contacts/manage-contacts';
import { DetailsModal } from './modals/details-modal/details-modal';

@NgModule({
  declarations: [
    AppComponent,
    MapSelectionPage,
    HomePage,
    NoAccessPage,
    DeleteModal,
    RenameModal,
    MapMetaDataModal,
    PasswordModal,
    CreateSessionModal,
    InvitationsModal,
    ShippingDetailsModal,
    StripePage,
    SuccessPage,
    SuccessRedirectPage,
    PaymentErrorPage,
    AddOnsPage,
    StripeModal,
    AdminPage,
    ForgotPasswordModal,
    MapSelectModal,
    ManageContactsPage,
    DetailsModal
  ],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideAuth(() => getAuth()),
    provideAuth(() =>
      initializeAuth(getApp(), { persistence: indexedDBLocalPersistence })
    ),
    provideDatabase(() => getDatabase()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
  ],
  exports: [],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: BUCKET, useValue: 'simtac-dms.appspot.com' },
    StatusBar,
    File,
    Stripe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
