/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/component-class-suffix */
import { Component } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { ContactCard, SimTacUser } from 'src/app/models/firestore-models';
import { Camera, CameraResultType } from '@capacitor/camera';
import { EventService } from 'src/app/services/event-service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'manage-contacts',
  templateUrl: 'manage-contacts.html',
  styleUrls: ['manage-contacts.scss'],
})
export class ManageContactsPage {
  public contactCard: ContactCard;
  public nameValue: string;
  public positionValue: string;
  public emailValue: string;
  public mobileValue: string;
  public workValue: string;
  public notesValue: string;
  public imgValue: string;
  public searching = true;
  public contactList: ContactCard[] = [];
  public searchResults: ContactCard[] = [];
  public userId: string;


  constructor(
    private alertController: AlertController,
    private eventService: EventService,
    private firestoreService: FirestoreService,
    private loadingController: LoadingController,
    private authService: AuthService,
    private router: Router,
    private csvParser: Papa
  ) {
  }

  async ngOnInit() {
    if (this.authService.user.value) {
      this.userId = this.authService.user.value.userId;
    }
    let loader = await this.loadingController.create({
      message: 'Loading Contacts...',
      duration: 2000,
    });

    await loader.present();
    this.authService.user.subscribe((user: SimTacUser) => {
      if (user) {
        this.userId = user.userId;
        this.firestoreService.getAllContactCards(this.userId);
      }
    });
    this.eventService.allContactCards.subscribe(async (allContacts: ContactCard[]) => {
      this.contactList = allContacts;
      this.searchResults = allContacts.sort((a: ContactCard, b: ContactCard) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });
      await loader.dismiss();
    });
    if (this.contactCard) {
      this.nameValue = this.contactCard.name;
      this.positionValue = this.contactCard.position;
      this.emailValue = this.contactCard.email;
      this.mobileValue = this.contactCard.mobile;
      this.workValue = this.contactCard.work;
      this.notesValue = this.contactCard.notes;
      this.imgValue = this.contactCard.image;
    }
    this.searchContact();
  }

  createNewContact() {
    this.nameValue = "";
    this.positionValue = "";
    this.emailValue = "";
    this.mobileValue = "";
    this.workValue = "";
    this.notesValue = "";
    this.imgValue = "";
    this.contactCard = undefined;
  }

  createContactButtonClicked() {
    this.createNewContact();
    this.searching = false;
  }

  changeContact(contact: ContactCard) {
    this.nameValue = contact.name;
    this.positionValue = contact.position;
    this.emailValue = contact.email;
    this.mobileValue = contact.mobile;
    this.workValue = contact.work;
    this.notesValue = contact.notes;
    this.imgValue = contact.image;
    this.contactCard = contact;
    this.closeSearch();
  }

  async saveContactDetails(contact: ContactCard, create: boolean, csv: boolean = false) {
    if (!csv) {
      let loader = await this.loadingController.create({
        message: 'Updating Contact Details...',
        duration: 2000,
      });
  
      await loader.present();
    }
    if (create) {
      await this.firestoreService.createContactCard(contact);
      this.contactList.push(contact);
    } else {
      await this.firestoreService.saveContactCardDetails(contact);
      this.contactList.forEach((existingContact, index) => {
        if (existingContact.contactCardId == contact.contactCardId) {
          this.contactList[index] = contact;
        }
      })
    }
    this.contactList = this.contactList.sort((a: ContactCard, b: ContactCard) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });
    this.eventService.allContactCards.next(this.contactList)
  }

  async confirmDetails() {
    if (this.contactCard) {
      const contact = new ContactCard(this.contactCard.userId, this.nameValue, this.positionValue, this.emailValue, this.mobileValue, this.workValue, this.notesValue, this.imgValue, this.contactCard.contactCardId);
      await this.saveContactDetails(contact, false);
    } else {
      const contact = new ContactCard(this.userId, this.nameValue, this.positionValue, this.emailValue, this.mobileValue, this.workValue, this.notesValue, this.imgValue, '');
      await this.saveContactDetails(contact, true);
    }
    this.dismiss();
  }

  async searchContact() {
    this.searching = true;
    this.contactList = this.eventService.allContactCards.value;
    this.searchResults = this.contactList;
  }

  async closeSearch() {
    this.searching = false;
    this.searchResults = [];
  }

  async updateSearchResults($event) {
    let searchValue = $event.detail.value.toLocaleLowerCase();
    this.searchResults = [];

    if (!searchValue) {
      return;
    }

    this.contactList.forEach((contact: ContactCard) => {
      if (contact.name.toLocaleLowerCase().includes(searchValue) || contact.email.toLocaleLowerCase().includes(searchValue) || contact.position.toLocaleLowerCase().includes(searchValue) || contact.mobile?.toString().toLocaleLowerCase().includes(searchValue) || contact.work?.toString().toLocaleLowerCase().includes(searchValue) || contact.notes.toLocaleLowerCase().includes(searchValue)) {
        this.searchResults.push(contact);
      }
    })
  }

  dismiss() {
    this.searchContact();
  }

  async parsedUploadedCsv(data: any) {
    let csvData = data[0];
    this.csvParser.parse(csvData,{
      complete: async (result) => {
        let loader = await this.loadingController.create({
          message: 'Parsing + Uploading Contacts...',
          duration: 3000,
        });
        await loader.present();
          console.log('Parsed: ', result.data);
          let contactsToUpload: ContactCard[] = [];
          for (let i = 0; i <= result.data.length -1; i++) {
            try {
              let img = '';
              if (result.data[i].length == 7) {
                img = result.data[i][6];
              }
              let contact = new ContactCard(this.userId, result.data[i][0], result.data[i][1], result.data[i][2], result.data[i][3],result.data[i][4], result.data[i][5], img, '')
              await this.saveContactDetails(contact, true, true);
              contactsToUpload.push(contact);
            } catch (ex) {
              console.log('error: ', ex)
            }
          }
          console.log('Uploaded ' + contactsToUpload.length + ' contatcs.');
          loader.dismiss();
      }
  });
    }

  public getUniqueFileNameKey(fileName: string) {
    const rnd = Math.random();
    const uniqueKey = fileName + rnd.toString();
    return uniqueKey;
  }

  selectPhotoFromGallery() {
    Camera.getPhoto({ resultType: CameraResultType.Base64, quality: 10 }).then(
      async (result) => {
        const png = 'data:image/png;base64,' + result.base64String;
        const name =
          this.eventService.getUniqueFileNameKey(this.userId) + '.png';
        const uploadTask = this.firestoreService.uploadImageToFirebase(
          result.base64String,
          name
        );
        let loader = await this.loadingController.create({
          message: 'Uploading...',
          duration: 3000,
        });
        await loader.present();
        let imgUrl = '';
        await uploadTask.then(async (uploadResult) => {
          imgUrl = await uploadResult.ref.getDownloadURL();
          loader.dismiss();
          if (this.contactCard)
          this.contactCard.image = imgUrl;
          this.imgValue = imgUrl;
        });
      }
    );
  }

  backButtonClicked() {
    this.router.navigateByUrl('map-selection');
  }

  async deleteButtonClicked() {
    const alert = await this.alertController.create({
      header: 'Delete?',
      message: 'Are you sure you want to delete ' + this.contactCard.name + '?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes',
          handler: async () => {
            this.searching = true;
            let loader = await this.loadingController.create({
              message: 'Deleting...',
              duration: 3000,
            });
            await loader.present();
            await this.deleteContact(this.contactCard);
            await this.firestoreService.getAllContactCards(this.userId);
            await loader.dismiss();
          },
        },
      ],
    });
    await alert.present();
  }

  async deleteContact(contact: ContactCard) {
    await this.firestoreService.deleteContactCard(contact);
  }
}
